import { createSlice } from "@reduxjs/toolkit";
import { GroupingField } from "../metadata/metadata.module";
import { GroupedField, GroupedResponseCount, groupId } from "./grouping.module";
import { Pricing, PricingRun } from "../pricing_list/pricingListSlice";
import { WithPayload } from "../../common/utils/withPayload";
import * as _ from "lodash";
import { mergeIncomingPricings } from "../live_update/LiveUpdate.merge";
import { visitDepthFirst } from "../../common/utils/tree";
import { isGroup } from "../pricing_groups/groupUtils";
import { LinqPredicate } from "../filters/utils";

export interface GroupingState {
	pricings: {
		selectedGroups: GroupingField[];
		count: {
			isLoading: boolean;
			error: any;
			data: GroupedResponseCount | null;
		};
		isLoading: boolean;
	};
}

export const groupingInitialState: GroupingState = {
	pricings: {
		selectedGroups: [],
		count: {
			isLoading: false,
			error: null,
			data: null,
		},
		isLoading: false,
	},
};

export function findGroup(state: GroupingState, groupPath: string[]) {
	let groups = state.pricings.count.data;
	let currentGroup = null;
	for (const groupKey of groupPath) {
		const nextGroup = _.find(
			groups,
			(group) => groupId(group) === groupKey
		);
		groups = nextGroup?.children || [];
		currentGroup = nextGroup;
	}
	return currentGroup;
}

export const groupingSlice = createSlice({
	name: "grouping",
	initialState: groupingInitialState,
	reducers: {
		reset: (state) => {
			state.pricings = groupingInitialState.pricings;
		},
		addGroup: (state, { payload }) => {
			state.pricings.selectedGroups.push(payload);
		},
		removeGroup: (state, { payload }) => {
			state.pricings.selectedGroups =
				state.pricings.selectedGroups.filter(
					(group) => group.field_key !== payload.field_key
				);
		},
		setGroups: (state, { payload }) => {
			state.pricings.selectedGroups = payload.groups;
		},
		clearGroups: (state) => {
			state.pricings.selectedGroups = [];
		},
		startLoadingGroupCount: (
			state,
			{ payload }: WithPayload<{ clear: boolean | undefined }>
		) => {
			state.pricings.count.error = null;
			state.pricings.count.isLoading = true;
			if (payload?.clear) {
				state.pricings.count.data = null;
			}
		},
		groupCountOverwrite: (
			state,
			{ payload }: WithPayload<GroupedField[]>
		) => {
			state.pricings.count.isLoading = false;
			state.pricings.count.data = payload;
		},
		groupCountSuccess: (
			state,
			{ payload }: WithPayload<GroupedField[]>
		) => {
			state.pricings.count.isLoading = false;
			const newGroups = payload.map((group) => {
				const newGroup = { ...group };
				visitDepthFirst(
					newGroup,
					(node, _depth, parents) => {
						const groupPath = [
							...parents.map((parent) => groupId(parent)),
							groupId(node),
						];
						const existingGroup = findGroup(state, groupPath);
						node.isExpanded =
							!!existingGroup?.isExpanded ||
							node.field_key === "All";
						if (existingGroup) {
							node.isSelected = !!existingGroup?.isSelected;
							if (!node.children?.length) {
								node.pricings = existingGroup?.pricings || [];
								if (!node.pricings?.length) {
									node.isExpanded = false;
								}
							} else {
								node.pricings = [];
								if (existingGroup.pricings?.length) {
									node.isExpanded = false;
								}
							}
							node.pricingsAreLoading =
								existingGroup?.pricingsAreLoading || false;
							node.thereAreMorePricingsToLoad =
								existingGroup?.thereAreMorePricingsToLoad;
							node.page = existingGroup?.page || 1;
						}
					},
					(node) => node.children,
					isGroup
				);
				return newGroup;
			});
			state.pricings.count.data = newGroups;
		},
		groupCountFailure: (state, { payload }) => {
			state.pricings.count.isLoading = false;
			state.pricings.count.error = payload;
		},
		expandGroup: (state, action: WithPayload<{ groupPath: string[] }>) => {
			const currentGroup = findGroup(state, action.payload.groupPath);
			if (currentGroup) {
				currentGroup.isExpanded = !currentGroup.isExpanded;
			}
		},
		expandPricing: (state, action: WithPayload<{ id: number }>) => {
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(node) => {
						node.pricings?.forEach((pricing) => {
							if (pricing.id === action.payload.id) {
								pricing.isExpanded = true;
							}
						});
					},
					(node) => node.children,
					isGroup
				);
			});
		},
		closePricing: (state, action: WithPayload<{ id: number }>) => {
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(node) => {
						node.pricings?.forEach((pricing) => {
							if (pricing.id === action.payload.id) {
								pricing.isExpanded = false;
								pricing.pricing_runs =
									pricing.pricing_runs.slice(0, 1);
							}
						});
					},
					(node) => node.children,
					isGroup
				);
			});
		},
		setPricingRuns: (
			state,
			action: WithPayload<{ id: number; runs: PricingRun[] }>
		) => {
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(group) => {
						const pricing = _.find(
							group.pricings,
							(next) => next.id === action.payload.id
						);
						if (pricing) {
							pricing.pricing_runs = action.payload.runs;
						}
					},
					(group) => group.children,
					isGroup
				);
			});
		},
		setPricingRunsLoading: (
			state,
			action: WithPayload<{ id: number; loading: boolean }>
		) => {
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(group) => {
						const pricing = _.find(
							group.pricings,
							(next) => next.id === action.payload.id
						);
						if (pricing) {
							pricing.isLoadingRuns = action.payload.loading;
						}
					},
					(group) => group.children,
					isGroup
				);
			});
		},
		selectGroup: (state, action: WithPayload<{ groupPath: string[] }>) => {
			const currentGroup = findGroup(state, action.payload.groupPath);
			if (currentGroup) {
				currentGroup.isSelected = !currentGroup.isSelected;
			}
		},
		selectPricing: (state, action: WithPayload<{ id: number }>) => {
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(group) => {
						const pricing = _.find(
							group.pricings,
							(next) => next.id === action.payload.id
						);
						if (pricing) {
							pricing.isSelected = !pricing.isSelected;
						}
					},
					(group) => group.children,
					isGroup
				);
			});
		},
		startPricingsLoading: (
			state,
			action: WithPayload<{ groupPath: string[] }>
		) => {
			const currentGroup = findGroup(state, action.payload.groupPath);
			if (currentGroup) {
				currentGroup.pricingsAreLoading = true;
			}
		},
		setPricings: (
			state,
			action: WithPayload<{
				groupPath: string[];
				pricings: Pricing[];
				thereAreMorePricingsToLoad: boolean;
			}>
		) => {
			const currentGroup = findGroup(state, action.payload.groupPath);
			if (currentGroup) {
				if (!currentGroup.pricings) {
					currentGroup.pricings = [];
				}
				currentGroup.pricings = mergeIncomingPricings(
					[],
					action.payload.pricings,
					currentGroup.filters,
					[]
				);
				currentGroup.pricingsAreLoading = false;
				currentGroup.thereAreMorePricingsToLoad =
					action.payload.thereAreMorePricingsToLoad;
				currentGroup.page = 1;
				if (currentGroup.field_key === "All") {
					currentGroup.isExpanded = true;
				}
			}
		},
		addPricings: (
			state,
			action: WithPayload<{
				groupPath: string[];
				pricings: Pricing[];
				thereAreMorePricingsToLoad: boolean;
				page: number;
			}>
		) => {
			const currentGroup = findGroup(state, action.payload.groupPath);
			if (currentGroup) {
				if (!currentGroup.pricings) {
					currentGroup.pricings = [];
				}
				currentGroup.pricings = mergeIncomingPricings(
					currentGroup.pricings,
					action.payload.pricings,
					currentGroup.filters,
					[]
				);
				currentGroup.pricingsAreLoading = false;
				currentGroup.thereAreMorePricingsToLoad =
					action.payload.thereAreMorePricingsToLoad;
				currentGroup.page = action.payload.page;
			}
		},
		mergePricings: (
			state,
			action: WithPayload<{
				pricings: Pricing[];
				extraPredicates?: LinqPredicate[];
			}>
		) => {
			state.pricings.count.data?.forEach((rootGroup) => {
				visitDepthFirst(
					rootGroup,
					(node) => {
						if (!node.children && node.isExpanded) {
							node.pricings = mergeIncomingPricings(
								node?.pricings || [],
								action.payload.pricings,
								node.filters,
								action.payload.extraPredicates || []
							);
						}
					},
					(node) => node.children || [],
					isGroup
				);
			});
		},
		clearGroupsAndPricingsSelection: (state) => {
			state.pricings.count.data?.forEach((group) => {
				visitDepthFirst(
					group,
					(node) => {
						node.isSelected = false;
						if (node.pricings) {
							node.pricings.forEach(
								(pricing) => (pricing.isSelected = false)
							);
						}
					},
					(node) => node.children
				);
			});
		},
		selectAllGroups: (state) => {
			state.pricings.count.data?.forEach((group) => {
				group.isSelected = true;
			});
		},
		statusStartLoading: (state) => {
			state.pricings.isLoading = true;
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(group) => {
						if (group.isSelected) {
							group.isStatusUpdating = true;
						}
						group.pricings?.forEach((pricing) => {
							pricing.isStatusUpdating =
								group.isSelected || pricing.isSelected;
						});
					},
					(group) => group.children,
					isGroup
				);
			});
		},
		statusEndLoading: (state) => {
			state.pricings.isLoading = false;
			state.pricings.count.data?.forEach((root) => {
				visitDepthFirst(
					root,
					(group) => {
						group.isStatusUpdating = false;
						group.pricings?.forEach((pricing) => {
							pricing.isStatusUpdating = false;
						});
					},
					(group) => group.children,
					isGroup
				);
			});
		},
	},
});

export const {
	reset,
	addGroup,
	groupCountOverwrite,
	removeGroup,
	setGroups,
	clearGroups,
	startLoadingGroupCount,
	groupCountFailure,
	groupCountSuccess,
	expandGroup,
	selectGroup,
	setPricings,
	mergePricings,
	addPricings,
	startPricingsLoading,
	selectPricing,
	clearGroupsAndPricingsSelection,
	selectAllGroups,
	statusStartLoading,
	statusEndLoading,
	expandPricing,
	closePricing,
	setPricingRuns,
	setPricingRunsLoading,
} = groupingSlice.actions;

export default groupingSlice.reducer;
