import React, {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { createPortal } from "react-dom";
import invariant from "tiny-invariant";

import { easeInOut } from "@atlaskit/motion/curves";
import { mediumDurationMs } from "@atlaskit/motion/durations";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import {
	attachClosestEdge,
	type Edge,
	extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
	draggable,
	dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { centerUnderPointer } from "@atlaskit/pragmatic-drag-and-drop/element/center-under-pointer";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { token } from "@atlaskit/tokens";

import { useBoardContext } from "./board-context";
import { CustomCard } from "./Card";
import {
	ColumnContext,
	type ColumnContextProps,
	useColumnContext,
} from "./column-context";
import {
	Box,
	Menu,
	MenuItem,
	Stack,
	SxProps,
	Theme,
	Typography,
	IconButton,
	Tooltip,
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { grey, primaryColor, secondaryColor } from "../../../../core/theme";
import {
	ArchivedTenderStatus,
	getWorkflowViewInfo,
	Order,
	TendersView,
} from "../../tender.constant";
import { ColumnType } from "../../tender.module";
import { useAppDispatch } from "../../../../common/hooks/default";
import { setColumnOrder } from "../../tenderSlice";
import {
	getColumnOrderText,
	isDefaultOrder,
	OrderColumn,
} from "../../utils/workflowView.util";
import ArchiveIcon from "@mui/icons-material/Archive";
import { TenderPageContext } from "../../TenderPage";
import { setPersistedFilters } from "../../../filters/filtersSlice";
import { useDispatch } from "react-redux";
import * as _ from "lodash";

const style: { [key: string]: SxProps<Theme> } = {
	columnStyles: {
		display: "flex",
		margin: "0 10px",

		width: "300px",
		borderRadius: "border.radius.300",
		transition: `background ${mediumDurationMs}ms ${easeInOut}`,
		position: "relative",
	},
	stackStyles: {
		minHeight: "0",
		height: "74vh",
		flexGrow: 1,
		width: "300px",
	},
	scrollContainerStyles: {
		backgroundColor: `var(--light-grey-grey-200, ${secondaryColor})`,
		height: "100%",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			width: "5px",
		},
		"&::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"&::-webkit-scrollbar-thumb": {
			background: "#888",
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "#555",
		},
	},
	cardListStyles: {
		boxSizing: "border-box",
		padding: "space.100",
		gap: "space.100",
	},
	columnHeaderStyles: {
		paddingInlineStart: "space.200",
		paddingInlineEnd: "space.200",
		paddingBlockStart: "space.100",
		color: "color.text.subtlest",
		userSelect: "none",
	},
	isDraggingStyles: {
		opacity: 0.4,
	},
	safariPreviewStyles: {
		width: "250px",
		backgroundColor: "elevation.surface.sunken",
		borderRadius: "border.radius",
		padding: "space.200",
	},
	archivedButton: {
		fontWeight: "600",
		fontSize: "16px",
		display: "flex",
		textDecoration: "underline",
		color: primaryColor,
		cursor: "pointer",
		margin: "8px 16px",
		"& svg": {
			marginRight: "5px",
		},
	},
};

type State =
	| { type: "idle" }
	| { type: "is-card-over" }
	| { type: "is-column-over"; closestEdge: Edge | null }
	| { type: "generate-safari-column-preview"; container: HTMLElement }
	| { type: "generate-column-preview" };

const idle: State = { type: "idle" };
const isCardOver: State = { type: "is-card-over" };

function SafariColumnPreview({ column }: { column: ColumnType }) {
	return (
		<Box sx={style.safariPreviewStyles}>
			<Typography variant="subtitle1">
				{getWorkflowViewInfo(column.status).title}
			</Typography>
		</Box>
	);
}

interface ActionMenuItemsProps {
	order?: Order;
	orderColumn?: OrderColumn;
	handleClose: () => void;
}

function ActionMenuItems(props: ActionMenuItemsProps) {
	const { order, orderColumn, handleClose } = props;
	const { columnId } = useColumnContext();
	const dispatch = useAppDispatch();

	const submitOrderColumn = (
		orderParam: Order,
		orderColumnParam: OrderColumn
	) => {
		dispatch(
			setColumnOrder({
				status: columnId,
				order: orderParam,
				orderColumn: orderColumnParam,
			})
		);
		handleClose();
	};
	return (
		<>
			<MenuItem
				sx={{ fontSize: 14, fontWeight: "500", marginBottom: "5px" }}
			>
				Sort by
			</MenuItem>
			<MenuItem
				onClick={() =>
					submitOrderColumn(Order.desc, OrderColumn.createdAt)
				}
				selected={
					order === Order.desc &&
					orderColumn === OrderColumn.createdAt
				}
			>
				{getColumnOrderText(Order.desc, OrderColumn.createdAt)}
			</MenuItem>
			<MenuItem
				onClick={() =>
					submitOrderColumn(Order.asc, OrderColumn.createdAt)
				}
				selected={
					order === Order.asc && orderColumn === OrderColumn.createdAt
				}
			>
				{getColumnOrderText(Order.asc, OrderColumn.createdAt)}
			</MenuItem>
			<MenuItem
				onClick={() =>
					submitOrderColumn(Order.asc, OrderColumn.dueDate)
				}
				selected={
					order === Order.asc && orderColumn === OrderColumn.dueDate
				}
			>
				{getColumnOrderText(Order.asc, OrderColumn.dueDate)}
			</MenuItem>
			<MenuItem
				onClick={() =>
					submitOrderColumn(Order.desc, OrderColumn.dueDate)
				}
				selected={
					order === Order.desc && orderColumn === OrderColumn.dueDate
				}
			>
				{getColumnOrderText(Order.desc, OrderColumn.dueDate)}
			</MenuItem>
		</>
	);
}

function ActionMenu({
	order,
	orderColumn,
}: {
	order?: Order;
	orderColumn?: OrderColumn;
}) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<Tooltip
				disableHoverListener={!order || !orderColumn}
				title={`Sorted by ${
					order && orderColumn
						? getColumnOrderText(order, orderColumn)
						: ""
				}`}
				placement="top"
			>
				<IconButton
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					<ImportExportIcon
						sx={{
							backgroundColor: isDefaultOrder(order, orderColumn)
								? "none"
								: grey,
							color: "black",
							padding: "2px",
						}}
					/>
				</IconButton>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<ActionMenuItems
					order={order}
					handleClose={handleClose}
					orderColumn={orderColumn}
				/>
			</Menu>
		</>
	);
}

export const Column = memo(function Column({ column }: { column: ColumnType }) {
	const { status: columnId, order, orderColumn } = column;
	const count = column.count || 0;
	const columnRef = useRef<HTMLDivElement | null>(null);
	const columnInnerRef = useRef<HTMLDivElement | null>(null);
	const headerRef = useRef<HTMLDivElement | null>(null);
	const scrollableRef = useRef<HTMLDivElement | null>(null);
	const [state, setState] = useState<State>(idle);
	const [isDragging, setIsDragging] = useState<boolean>(false);

	const dispatch = useDispatch();

	const { instanceId, registerColumn } = useBoardContext();
	const value = useContext(TenderPageContext);

	const navigateToListView = (status: string) => {
		const predicates = [
			`status in ('${status}',)`,
			"archived in ('true',)",
		];
		const odataString = `"${predicates.join(" and ")}"`;
		const filtersToPersist = JSON.stringify({
			archived: [
				{
					id: true,
					name: "Archived",
				},
			],
			tenders_status: [
				{
					id: status,
					name: status,
				},
			],
		});
		dispatch(
			setPersistedFilters({
				tenders_query: odataString,
				tenders_filters: filtersToPersist,
				tenders_filters_count: "2",
				tenders_filter_template: "CUSTOM",
			})
		);
		if (value) value.setActiveView(_.camelCase(TendersView.LIST_VIEW));
	};

	useEffect(() => {
		invariant(columnRef.current);
		invariant(columnInnerRef.current);
		invariant(headerRef.current);
		invariant(scrollableRef.current);
		return combine(
			registerColumn({
				columnId,
				entry: {
					element: columnRef.current,
				},
			}),
			draggable({
				element: columnRef.current,
				dragHandle: headerRef.current,
				getInitialData: () => ({
					columnId,
					type: "column",
					instanceId,
				}),
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					const isSafari: boolean =
						navigator.userAgent.includes("AppleWebKit") &&
						!navigator.userAgent.includes("Chrome");

					if (!isSafari) {
						setState({ type: "generate-column-preview" });
						return;
					}
					setCustomNativeDragPreview({
						getOffset: centerUnderPointer,
						render: ({ container }) => {
							setState({
								type: "generate-safari-column-preview",
								container,
							});
							return () => setState(idle);
						},
						nativeSetDragImage,
					});
				},
				onDragStart: () => {
					setIsDragging(true);
				},
				onDrop() {
					setState(idle);
					setIsDragging(false);
				},
			}),
			dropTargetForElements({
				element: columnInnerRef.current,
				getData: () => ({ columnId }),
				canDrop: ({ source }) => {
					return (
						source.data.instanceId === instanceId &&
						source.data.type === "card"
					);
				},
				getIsSticky: () => true,
				onDragEnter: () => setState(isCardOver),
				onDragLeave: () => setState(idle),
				onDragStart: () => setState(isCardOver),
				onDrop: () => setState(idle),
			}),
			dropTargetForElements({
				element: columnRef.current,
				canDrop: ({ source }) => {
					return (
						source.data.instanceId === instanceId &&
						source.data.type === "column"
					);
				},
				getIsSticky: () => true,
				getData: ({ input, element }) => {
					const data = {
						columnId,
					};
					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ["left", "right"],
					});
				},
				onDragEnter: (args) => {
					setState({
						type: "is-column-over",
						closestEdge: extractClosestEdge(args.self.data),
					});
				},
				onDrag: (args) => {
					setState((current) => {
						const closestEdge: Edge | null = extractClosestEdge(
							args.self.data
						);
						if (
							current.type === "is-column-over" &&
							current.closestEdge === closestEdge
						) {
							return current;
						}
						return {
							type: "is-column-over",
							closestEdge,
						};
					});
				},
				onDragLeave: () => {
					setState(idle);
				},
				onDrop: () => {
					setState(idle);
				},
			}),
			autoScrollForElements({
				element: scrollableRef.current,
				canScroll: ({ source }) =>
					source.data.instanceId === instanceId &&
					source.data.type === "card",
			})
		);
	}, [columnId, registerColumn, instanceId]);

	const stableItems = useRef(column.tenders);
	useEffect(() => {
		stableItems.current = column.tenders;
	}, [column.tenders]);

	const getCardIndex = useCallback((id: number) => {
		return stableItems.current.findIndex((item) => item.id === id);
	}, []);

	const getNumCards = useCallback(() => {
		return stableItems.current.length;
	}, []);

	const contextValue: ColumnContextProps = useMemo(() => {
		return { columnId, getCardIndex, getNumCards };
	}, [columnId, getCardIndex, getNumCards]);

	return (
		<ColumnContext.Provider value={contextValue}>
			<Box sx={style.columnStyles} ref={columnRef}>
				<Stack sx={style.stackStyles} ref={columnInnerRef}>
					<Stack
						sx={
							{
								...style.stackStyles,
								...(isDragging ? style.isDraggingStyles : {}),
							} as SxProps
						}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								...style.columnHeaderStyles,
							}}
							ref={headerRef}
						>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography
									variant="h4"
									fontSize={16}
									fontWeight={600}
								>
									{getWorkflowViewInfo(column.status).title}
									&ensp;&ensp;{count}&ensp;&ensp;
								</Typography>
							</Box>
							<ActionMenu
								order={order}
								orderColumn={orderColumn}
							/>
						</Box>
						<Box
							className={`column-${column.status}`}
							sx={style.scrollContainerStyles}
							ref={scrollableRef}
						>
							<Stack sx={style.cardListStyles}>
								{column.tenders.length > 0 &&
									column.tenders.map((item) => (
										<CustomCard
											status={column.status}
											item={item}
											key={item.id}
										/>
									))}
							</Stack>
							{ArchivedTenderStatus.includes(columnId) && (
								<Box
									sx={style.archivedButton}
									onClick={() => navigateToListView(columnId)}
								>
									<ArchiveIcon />
									Archived
								</Box>
							)}
						</Box>
					</Stack>
				</Stack>
				{state.type === "is-column-over" && state.closestEdge && (
					<DropIndicator
						edge={state.closestEdge}
						gap={token("space.200", "0")}
					/>
				)}
			</Box>
			{state.type === "generate-safari-column-preview"
				? createPortal(
						<SafariColumnPreview column={column} />,
						state.container
				  )
				: null}
		</ColumnContext.Provider>
	);
});
