import { Formik, type FormikProps } from "formik";
import Counterparty from "./CounterPartyFields";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../../contract_validation/formik/counterpartyFormik";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_geco/gepoTprProxyApi";
import { Box } from "@mui/material";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetBooksQuery } from "../../../requests_geco/referentialApi/referentialApi";
import { useCallback, useMemo } from "react";
import { NJRadioGroup, NJRadio } from "@engie-group/fluid-design-system-react";
import CustomSelect from "../../../common/components/CustomSelect";

export interface CounterpartyFormProps {
	formRef: React.Ref<FormikProps<CounterpartyFormik>>;
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
}

const CounterpartyForm = ({
	formRef,
	contract,
	onSubmit,
}: CounterpartyFormProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();

	const { data: books, isLoading: isBookLoading } =
		useRtkQueryDynamicEndpoint(useGetBooksQuery)({});

	const booksOptions = useMemo(() => {
		const options: { label: string; value: string | undefined, key: string | undefined }[] = [
			{ label: "No Mirror Book", value: undefined, key: undefined },
		];
		for (const book of books || []) {
			options.push({ label: book.name, value: book.id, key: book.id });
		}

		return options;
	}, [books]);



	const onSubmitMiddelware = useCallback((formikData: CounterpartyFormik): void => {
		if(formikData?.mirror_book) {
			
			formikData.mirror_book = (books || [])?.find((book) => Number(book.id) === Number(formikData?.mirror_book))
		}

		return onSubmit(formikData)
	}
, [onSubmit,books])
	

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmitMiddelware}
			innerRef={formRef}
		>
			{({ handleChange, setFieldValue, handleSubmit, values, errors }) => (
				<form onSubmit={handleSubmit} id="counterPartyForm">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Counterparty
							values={values}
							parties={parties?.partyResults || []}
							detailParty={detailParty}
							errorMessage={detailPartyError as ErrorType}
							isLoading={isDetailLoading || isSearchLoading || isBookLoading}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							searchParties={searchParties}
							getPartyDetail={getPartyDetail}
							label="Counterpart"
						/>
						<CustomSelect
							value={values.mirror_book as string}
							name={"mirror_book"}
							label={"Mirror Book"}
							onChange={handleChange}
							//@ts-ignore
							items={booksOptions}
							error={!!errors.mirror_book}
							errorText={errors.mirror_book}
							/>

						<NJRadioGroup
							checkedId={values.way}
							legend="Contract Way"
							onChange={(way) => {
								setFieldValue("way", way);
							}}
							orientation="row"
						>
							<NJRadio
								id={"Buy"}
								label={"Buy"}
								name="way"
								value={"Buy"}
							/>
							<NJRadio
								id={"Sell"}
								label={"Sell"}
								name="way"
								value={"Sell"}
							/>
						</NJRadioGroup>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default CounterpartyForm;
