import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/default";
import { selectPricingSelectedGroupingFields } from "../../grouping/grouping.selector";
import usePrevious from "../../../common/hooks/usePrevious";
import { reloadPricingsGroupCount } from "../../grouping/grouping.thunk";

export function useReloadGroups(tenderId?: number, odataQuery?: string) {
	const dispatch = useAppDispatch();
	const selectedGroups = useAppSelector(selectPricingSelectedGroupingFields);
	const previousOdataQuery = usePrevious(odataQuery);

	useEffect(() => {
		if (
			tenderId &&
			previousOdataQuery !== odataQuery &&
			previousOdataQuery !== "N/A" &&
			odataQuery !== "N/A"
		) {
			dispatch(reloadPricingsGroupCount(tenderId, odataQuery));
		}
	}, [selectedGroups, dispatch, tenderId, odataQuery, previousOdataQuery]);
}
