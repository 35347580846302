import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { PrimaryButton } from "../../../common/components/CustomButton";
import DoneIcon from "@mui/icons-material/Done";
import { If } from "../../../common/components/If";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { errorColor, successPrimary } from "../../../core/theme";

interface WarningCleaningModalProps {
	onClose: () => void;
	isOpen: boolean;
	warnings: { check: boolean; message: string }[];
}

function WarningCleaningModal(props: WarningCleaningModalProps) {
	const { onClose, isOpen, warnings } = props;

	return (
		<Dialog
			PaperProps={{ style: { minWidth: "30%", minHeight: "10%" } }}
			onClose={onClose}
			open={isOpen}
		>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
				{warnings.map((warning) => (
					<Box
						key={warning.message}
						sx={{
							fontSize: "16px",
							display: "flex",
							marginBottom: "12px",
						}}
					>
						<If condition={warning.check}>
							<DoneIcon
								sx={{
									marginRight: "16px",
									color: successPrimary,
								}}
							/>
						</If>
						<If condition={!warning.check}>
							<ReportProblemIcon
								sx={{ marginRight: "16px", color: errorColor }}
							/>
						</If>

						{warning.message}
					</Box>
				))}
			</DialogContent>
			<DialogActions>
				<DialogActions>
					<PrimaryButton
						onClick={onClose}
						text="Cancel"
						type="button"
						color="secondary"
					></PrimaryButton>
				</DialogActions>
			</DialogActions>
		</Dialog>
	);
}

export default WarningCleaningModal;
