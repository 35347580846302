import { PaginationType } from "../gecoTypes";
import { BookType, OtClickTypes } from "../referentialApi/referentialApi.types";
import { CurtailmentObjectType } from "../sitesApi/sitesApi.types";

export enum ContractAgreementType {
	PPA = "PPA",
	CPPA = "CPPA",
	ASSET = "ASSET",
}

export enum LagCalendar {
	EEX = "EEX",
}

export enum ClickUnit {
	MWH = "MWh",
	PERCENTAGE = "percentage",
}

export enum ClickLagType {
	PREVIOUS_MONTH = "PreviousMonth",
}

export enum LagBusinessDayConvention {
	Following = "following",
	Preceding = "preceding",
}

export enum ClickabilityType {
	OTC = "OTC",
	TAS = "TAS",
	FORMULA = "Formula",
}

export enum FixingMethod {
	AVERAGING = "Averaging",
	AVERAGING_NPQ = "AveragingNPQ",
	AVERAGING_YEAR_TO_DATE = "AveragingYearToDate",
}

export enum ContractStatus {
	CHANGE_APPROVED = "change_approved",
	CHANGE_REJECTED = "change_rejected",
	DRAFT = "draft",
	TO_REVIEW = "to_review",
	UNDER_APPROVAL = "under_approval",
	VALID = "valid",
}

export enum TradeType {
	PPA_PHYSICAL = "PPAPhysical",
	PPA_BASE_PHYSICAL = "PPABasePhysical",
}

export enum AdjustmentsFrequency {
	MONTHLY = "Monthly",
	YEARLY = "Yearly",
	QUARTERLY = "Quarterly",
	DAILY = "Daily",
	WEEKLY = "Weekly",
	SEMI_ANNUALLY = "SemiAnnually",
	AT_MATURITY = "AtMaturity",
}

export enum BaseProdType {
	FIRM = "Firm",
	CLICKABLE = "Clickable",
}

export enum NegativePriceType {
	PRICE = "Price",
	CENTS = "Cents",
}

export enum DealConfigMethods {
	"apply_index",
	"commodity_cash_strip",
	"commodity_profiled_swap",
	"csh_bc",
	"csh_pc",
	"forecast_only",
	"gefwd",
	"gefwd_basic",
	"gefwd_click",
	"gefwd_click_da_settlement",
	"gefwi",
	"gefwi_click",
	"gefwi_click_da_settlement",
	"gefwi_da_bp",
	"gefwi_eneco1_buy",
	"gefwi_eneco1_sell",
	"gefwi_eneco2_buy",
	"gefwi_eneco2_sell",
	"gefwi_index",
	"gefwi_index_bp",
	"gefwi_index_bp_click",
	"gefwi_ot",
	"getra_de",
	"getra_ot",
	"goo_certificate",
	"physical_commodity_forward",
	"ppa_financial",
	"ppa_financial_base_prod",
	"ppa_physical",
	"ppa_physical_base_prod",
}

export enum FeeName {
	SERVICE_FEE = "service fee",
	AGGREGATION_FEE = "aggregation fee",
	TRANSFORMATION_FEE = "transformation fee",
}

export enum DealType {
	COMPENSATION = "COMPENSATION",
	COMPENSATION_GOO = "COMPENSATION_GOO",
	POWER = "POWER",
	GOO = "GOO",
}

export enum ContractPeriodSettlementCategories {
	ppa_physical = "ppa_physical",
	ppa_base_physical = "ppa_base_physical",
	ppa_financial = "ppa_financial",
}

export enum CurtailmentObjectSubtypeType {
	"GRID" = "GRID",
	"CONTRACT" = "CONTRACT",
}

export enum AveragingType {
	DAILYWEIGHTED = "DailyWeighted",
	INDEX = "Index",
	WEIGHTED = "Weighted",
}

export enum PartialKindOfSettlements {
	BASE_PROD_PRICE = "Base Production Price",
	BP_DELTA_1 = "Base Production Delta 1",
	BP_DELTA_2 = "Base Production Delta 2",
	COMMODITY_PRICE = "Commodity Price",
	COMMODITY_STRIKE = "Commodity Strike",
	STANDARD = "Standard",
	NEGATIVE = "Negative",
}

export enum ComputationalMethod {
	BASE_OVER_PRODUCTION = "BaseOverProduction",
	STANDARD = "Standard",
	YEAR_TO_DATE = "YearToDate",
}

export enum BaseProductionSubtype {
	BASELOAD = "Baseload",
	THRESHOLD = "Threshold",
}

export enum DeltaType {
	ALL = "All",
	OVER_PRODUCTION = "OverProduction",
	UNDER_PRODUCTION = "UnderProduction",
}

export enum DeltaGranularity {
	HALF_HOURLY = "HalfHourly",
	HOURLY = "Hourly",
	MONTHLY = "Monthly",
	NONE = "None",
}

export interface ContractType {
	id: number;
	name: string;
	agreement_type: string;
	book: string;
	uid: string;
	buyer: string;
	country: string;
	end_date: string;
	seller: string;
	start_date: string;
	status: ContractStatus;
	nb_contractperiods: number;
	contract_type: string;
	signature_date: string;
	payment_market_places: string;
	language: string;
	sites: {
		id: number;
		name: string;
	}[];
	servicepoints: {
		id: number;
		name: string;
		code?: string;
	}[];
	[key: string]: any;
}

export interface GetContractsResponseType {
	pagination: PaginationType;
	result: ContractType[];
}

export type OrderType = "ASC" | "DESC";

export interface Fee {
	name: FeeName;
	value: number;
}

export interface SettlementType {
	id: number;
	type: PartialKindOfSettlements | string;
	period: string;
	price?: {
		commodity: number;
		fees: Fee[];
	};
	index?: {
		commodity_fixing_id: number;
		fixing_method: FixingMethod;
		averaging_type?: AveragingType;
		npqrs?: number[]; // [n,p,q,r,s]
		ref_contract?: string;

		beta: {
			commodity: number;
			fees: Fee[];
		};
		alpha: {
			commodity: number;
			fees: Fee[];
		};
	};
	[key: string]: any;
}

export interface BaseProdSettlementType extends SettlementType {
	computation_method: ComputationalMethod;
	base_prod_subtype: BaseProductionSubtype;
}

export interface BaseProdDeltaSettlementType extends SettlementType {
	delta: {
		type: DeltaType;
		granularity: DeltaGranularity;
	};
}

export interface PpaPhysicalModel {
	settlements: SettlementType[];
}

export interface PpaBasePhysicalModel extends PpaPhysicalModel {
	base_prod_capacity: number;
	base_prod_type: BaseProdType;
}

export interface InvoicingModel {
	invoice_decomposition: boolean;
	has_adjustments: boolean;
	adjustment_frequency: AdjustmentsFrequency;
}

export interface NegativePrice {
	index?: number | undefined;
	type?: NegativePriceType | undefined;
	value?: number | undefined;
}

export interface AlphaOrBeta {
	commodity: number;
	fees: {
		name: "aggregation_fee" | "service_fee";
		value: number;
	}[];
}

export interface ClickabilityInformation {
	click_period_frequency: OtClickTypes;
	alpha: AlphaOrBeta;
	beta: AlphaOrBeta;
	type: ClickabilityType;
	commodity_fixing_id: number;
	fixing_method: FixingMethod;
	use_future_dates: boolean;
	last_click_lag: number;
	lag_business_day_convention: LagBusinessDayConvention;
	lag_calendar: LagCalendar;
	click_lag_type: ClickLagType;
	commodity_reference_id: number;
	commodity_source: string;
	commodity_fixing: string;
	average_type: ClickabilityType;
	npqrs: string; // (n,p,q,r,s)
}

export interface Clickability {
	id: number;
	min_click_percentage: number;
	max_click_percentage: number;
	clickability_information: ClickabilityInformation;
	max_nb_click: number;
	max_nb_declick: number;
	right_to_seller: boolean;
	allow_full_declick: boolean;
	max_capacity: number;
	min_capacity: number;
}

export interface BCVConfigType {
	id?: number;
	name?: string;
}
export interface ContractPeriodType {
	id: number;
	start_date: string;
	end_date: string;
	clickability: Clickability;
	commodity: string;
	currency: string;
	goo_price: number;
	test_phase: boolean;
	invoicing: InvoicingModel;
	name: string;
	negative_price?: NegativePrice;
	ppa_base_physical: PpaBasePhysicalModel;
	ppa_physical: PpaPhysicalModel;
	sites: {
		id: number;
		name: string;
	}[];
	servicepoints: {
		id: number;
		name: string;
		code?: string;
	}[];
	trade_type: TradeType;
	status?: ContractStatus;
	bcv_config?: BCVConfigType;
	bcv_config_goo?: BCVConfigType;
	[key: string]: string | any;
}

export interface GetContractParamsType {
	contract_id: number;
	skip_validation?: boolean;
}

export interface ContractParty {
	short_name: string;
	name: string;
	is_internal: boolean;
	mnemonic: string;
	party_id: number;
}


export interface Contact {
	email: string;
	id: number;
	recipient_types: string[];
}

export interface ContractDetailType {
	id: number;
	name: string;
	clickability: {};
	contract_uid: string;
	contract_periods: ContractPeriodType[];
	agreement_type: ContractAgreementType;
	language: string;
	mirror_book: BookType;
	mirror_book_owner: string;
	party: ContractParty;
	status: ContractStatus;
	trade_date: string;
	trader_id: string;
	trading_book: BookType;
	start_date: string;
	end_date: string;
	trading_book_owner: string;
	way: "Buy" | "Sell" | undefined;
	techno: string;
	techno_type: string;
	timezone: string;
	country: string;
	contacts: Contact[];
}

export interface GetContractPeriodClickObjectsParamsType {
	contract_period_id: number;
	type_id__in?: string[];
	type_id__not_in?: string[];
	click_start__ge?: string;
	click_start__le?: string;
	page?: number;
	sortOn?: string;
	order?: OrderType;
}

export interface ContractPeriodClickObjectType {
	click_date: string;
	click_end: string;
	click_start: string;
	id: number;
	last_sync_update: string;
	mid_price: number;
	name: string;
	ot_sync: boolean;
	price: number;
	type_id: number;
	volume_percentage: number;
}

export interface ContractPeriodDealsResponseType {
	bcv_sync_error: string;
	bcv_sync_status: string;
	curtailment_object_id: number;
	curtailment_object_name: string;
	deal_type: DealType;
	end_date: string;
	id: number;
	last_sync_time: string;
	start_date: string;
	sync_error: string;
	sync_status: string;
	trade_id: number;
	year: number;
}

export interface ContractPeriodClickObjectsResponseType {
	pagination: PaginationType;
	result: ContractPeriodClickObjectType[];
}

export interface ContractPeriodServicePoint {
	id?: number;
	end_date: string;
	start_date: string;
	volume_share: number;
}

export interface UnlinkContractPeriodServicePoints {
	contractPeriod_id: string;
	servicepoint_id: string;
}

export interface UpsertContractPeriodServicePoints {
	contractPeriod_id: string;
	servicepoint_id: string;
	contractperiod_servicepoints: ContractPeriodServicePoint[];
}

export interface GetContractPeriodServicePointsType {
	contractPeriod_id: string;
	page?: number;
	per_page?: number;
	sort?: string;
}

export interface ServicePointType {
	code: "string";
	contractperiod_servicepoints: ContractPeriodServicePoint[];
	dso: {
		id: number;
		name: "string";
	};
	id: number;
	installed_capacity: number;
	name: "string";
	servicepoint_type: {
		id: number;
		name: "string";
	};
	site_name: "string";
	tso: {
		id: number;
		name: "string";
	};
}

export interface ContractPeriodServicePointsResponseType {
	pagination: PaginationType;
	result: ServicePointType[];
}

export interface ErrorType {
	data: string | { errors: string[] };
}

export interface ApiErrorType {
	data:
		| {
				loc: (string | number)[];
				msg: string;
		  }[]
		| { errors: string[] };
}

export interface HorizonChangeType {
	timezone: string;
	target_entity_name: string;
	target_name: string;
	target_id: number;
	old_value: string;
	new_value: string;
	target_attribute_name: string;
	isDiscarded?: boolean;
}

export interface HorizonChangeContractPeriodResponseType {
	proposed_changes: HorizonChangeType[];
}

export interface GetHorizonChangeParams {
	contract_period_id: number;
	field_name: "start_date" | "end_date";
	old_value: string;
	new_value: string;
	realign_related_contracts?: boolean;
	turn_safety_off: boolean;
}

export interface BookingLogItemType {
	id: string;
	log_level: string;
	message: string;
	system: string;
	timestamp: string;
}

export type BookingLogsListType = BookingLogItemType[];

export interface GetBookingLogsParamsType {
	contract_period_id: number;
	logs_from?: string;
}

export type ContractPeriodCurtailmentObjectsResponseType =
	CurtailmentObjectType[];

export interface ContractPeriodIdParamsType {
	contract_period_id: number;
}

export type ContractPeriodCurtailmentObjectsAudit = {
	bcv_config: string;
	commodity_index: string;
	commodity_index_id: number;
	compensation_deal_type: string;
	compensation_method: string;
	counterpart_kind: string;
	curtailment_kind: string;
	curtailment_object_subtype: string;
	curtailment_price: number;
	end_date: string;
	id: number;
	invoice_price: number;
	is_compensable: boolean;
	name: string;
	pricing_method: string;
	site: string;
	start_date: string;
	tso_or_dso_counterpart: string;
	virtual_portfolio: string;
};

export type ContractPeriodCurtailmentObjectsAuditsResponseType =
	ContractPeriodCurtailmentObjectsAudit[];

export interface ContractPeriodCurtailmentObjectsAuditsParamsType {
	curtailment_object_id: number;
}

export interface ContractAttachementType {
	id: number;
	name: string;
	filename: string;
	file: string;
}
export type ContractAttachementsResponseType = ContractAttachementType[];
export interface ContractAttachementsParamsType {
	contract_id: number;
}

export interface ContractAttachementUploadParamType {
	contract_id: number;
	filename: string;
}
export interface ContractAttachementUploadResponseType {
	id: number;
	filename: string;
	name: string;
	presigned_upload_url: string;
}

export interface ContractAttachementDeleteParamType {
	contract_id: number;
	attachement_id: number;
}
