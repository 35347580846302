import { CompactTender, Tender } from "../tender.module";
import { Order } from "../tender.constant";
import { User } from "../../authentication/auth.model";
import { isUserOriginatorAssignedToTender } from "../../../common/authorization/tender.authorization";
import { TenderStatus } from "../../pricing_list/pricingListSlice";

function stringToColor(string: string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: "32px",
			height: "32px",
			fontSize: "16px",
		},
		children: `${name.split(" ")[0][0]}${
			name.split(" ")[1][0]
		}`.toUpperCase(),
	};
}

export function mapTenderToWorkflowView(tender: Tender): CompactTender {
	return {
		id: tender.id,
		name: tender.name,
		creation_date: tender.creation_date,
		country_iso2_code: tender.country_iso2_code,
		site_count: tender.site_count,
		direction: tender.direction,
		due_date: tender.due_date,
		trader: tender.trader,
		originator: tender.originator,
		actions_blotter: tender.actions_blotter,
	};
}

export enum OrderColumn {
	dueDate = "due_date",
	createdAt = "created_at",
}

export const isDefaultOrder = (order?: Order, orderColumn?: OrderColumn) => {
	if (
		(!order && !orderColumn) ||
		(order === Order.desc && orderColumn === OrderColumn.createdAt)
	)
		return true;
	return false;
};

export const getColumnOrderText = (order: Order, orderColumn: OrderColumn) => {
	switch (true) {
		case order === Order.desc && orderColumn === OrderColumn.createdAt:
			return "Default (Creation date, newest first)";
		case order === Order.asc && orderColumn === OrderColumn.createdAt:
			return "Creation date (oldest first)";
		case order === Order.asc && orderColumn === OrderColumn.dueDate:
			return "Deadline date (closest first)";
		case order === Order.desc && orderColumn === OrderColumn.dueDate:
			return "Deadline date (furthest first)";
	}
};
export const invalidTransitions = (
	tender: Tender,
	originator: User | undefined
): any => {
	if (isUserOriginatorAssignedToTender(tender, originator)) {
		return {
			[TenderStatus.NEW]: [],
			[TenderStatus.TODO]: [],
			[TenderStatus.BOOKED]: [],
			[TenderStatus.CLOSED]: [],
			[TenderStatus.LOST]: [],
			[TenderStatus.ONGOING]: [],
			[TenderStatus.PRICING_DONE]: [],
			[TenderStatus.TO_BE_CHECKED]: [],
			[TenderStatus.UNDER_OFFER]: [],
			[TenderStatus.WON]: [],
		};
	}
	return {
		[TenderStatus.NEW]: [...Object.values(TenderStatus)],
		[TenderStatus.TODO]: [TenderStatus.NEW],
		[TenderStatus.BOOKED]: [TenderStatus.NEW],
		[TenderStatus.CLOSED]: [TenderStatus.NEW],
		[TenderStatus.LOST]: [TenderStatus.NEW],
		[TenderStatus.ONGOING]: [TenderStatus.NEW],
		[TenderStatus.PRICING_DONE]: [TenderStatus.NEW],
		[TenderStatus.TO_BE_CHECKED]: [TenderStatus.NEW],
		[TenderStatus.UNDER_OFFER]: [TenderStatus.NEW],
		[TenderStatus.WON]: [TenderStatus.NEW],
	};
};
