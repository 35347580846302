import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Chip, SxProps, Theme } from "@mui/material";
import { startOfTomorrow } from "date-fns";
import { Formik } from "formik";
import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PrimaryButton } from "../../common/components/CustomButton";
import CustomInput from "../../common/components/CustomInput";
import CustomSelect from "../../common/components/CustomSelect";
import { If } from "../../common/components/If";
import PageContent, {
	PageContentVariantType,
} from "../../common/components/PageContent";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { useAppDispatch } from "../../common/hooks/default";
import { useSearchPaginate } from "../../common/hooks/useSearchPaginate";
import useTimeout from "../../common/hooks/useSetTimeout";
import { WsMessage } from "../../core/socketProvider";
import { borderGrey } from "../../core/theme";
import wsPublisher$ from "../../core/wsPublisher";
import { PATH } from "../../router-path";
import {
	selectUserGroups,
	selectUserIdentity,
} from "../authentication/auth.selector";
import { Groups } from "../authentication/authentication.constant";
import TenderAndPricingFiltersDrawer from "../filters/Filters";
import {
	selectPersistedFilters,
	selectTendersOdataQuery,
} from "../filters/filters.selector";
import {
	filterTemplates,
	setFiltersDrawerOpened,
	setPersistedFilters,
} from "../filters/filtersSlice";
import { parseFromTemplate } from "../filters/utils";
import CollapsibleTable from "./components/TenderTable";
import ViewToggle from "./components/ViewToggle";
import { useTenderBatchActions } from "./hooks/useTenderBatchActions";
import { useTenderSelection } from "./hooks/useTenderSelection";
import {
	selectIsPaginationStopped,
	selectIsTenderListLoading,
	selectTenders,
} from "./tender.selector";
import {
	getTenders,
	mergeIncomingTenderUpdates,
	mergeIncomingTenders,
	updateWorkflowView,
} from "./tender.thunk";

import { initTenders } from "./tenderSlice";
import WorkflowView from "./components/WorkflowView";
import * as _ from "lodash";
import { TendersView } from "./tender.constant";

const style: { [key: string]: SxProps<Theme> } = {
	searchInput: {
		minWidth: "325px",
		"& svg": {
			color: borderGrey,
		},
	},
	snackbar: {
		cursor: "pointer",
	},
};

interface ITenderPageContext {
	activeView: string;
	setActiveView: (value: string) => void;
}

export const TenderPageContext = createContext<ITenderPageContext | null>(null);

function TenderPage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const groups = useSelector(selectUserGroups);
	const tenders = useSelector(selectTenders);
	const isTenderListLoading = useSelector(selectIsTenderListLoading);
	const isPaginationStopped = useSelector(selectIsPaginationStopped);
	const odataQuery = useSelector(selectTendersOdataQuery);
	const identity = useSelector(selectUserIdentity);

	const [searchParams, setSearchParams] = useSearchParams();

	const [activeView, setActiveView] = useState(
		_.camelCase(searchParams.get("mode") || TendersView.LIST_VIEW)
	);

	useEffect(() => {
		setSearchParams(() => {
			const params = new URLSearchParams();
			params.set("mode", _.kebabCase(activeView));
			return params;
		});
	}, [activeView]);

	const templates = useMemo(() => {
		const userGroups: Groups[] = [];
		if (groups?.isOriginator) {
			userGroups.push(Groups.originator);
		}
		if (groups?.isTrader) {
			userGroups.push(Groups.trader);
		}

		return filterTemplates.filter((f: any) =>
			f.roles.some((r: any) => userGroups.includes(r))
		);
	}, [groups]);

	const delayUntilNextDay = () =>
		startOfTomorrow().getTime() - new Date().getTime();

	const [filterRefreshDelay, setFilterRefreshDelay] =
		useState<number>(delayUntilNextDay);

	const fetchTenders = (page: number, search: string) => {
		dispatch(getTenders(page, search));
	};

	const { nextPage, currentPage, currentSearch, onSearch } =
		useSearchPaginate(fetchTenders);

	const onTenderMessageCreated = useCallback((messages: WsMessage<any>[]) => {
		dispatch(mergeIncomingTenders(messages.map((message) => message.data)));
		dispatch(updateWorkflowView(messages.map((message) => message.data)));
	}, []);

	const onTenderMessageUpdated = useCallback(
		(messages: WsMessage<any>[]) => {
			dispatch(
				mergeIncomingTenderUpdates(
					messages.map((message) => message.data)
				)
			);

			dispatch(
				updateWorkflowView(messages.map((message) => message.data))
			);
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_CREATED],
			onTenderMessageCreated
		);
	}, [onTenderMessageCreated]);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_UPDATED],
			onTenderMessageUpdated
		);
	}, [onTenderMessageUpdated]);

	const persistedFilters = useSelector(selectPersistedFilters);
	const filterCount = useMemo(() => {
		return persistedFilters.filters?.tenders_filters_count ?? 0;
	}, [persistedFilters.filters?.tenders_filters_count]);

	const [filtersResetAt, setFiltersResetAt] = useState<Date | undefined>();

	const [selectedTemplate, setSelectedTemplate] = useState<string | null>(
		persistedFilters.filters?.tenders_filter_template ?? ""
	);

	const {
		selectedTenders,
		selectedIds,
		toggleTenderSelection,
		selectAllTenders,
		clearSelectedTenders,
		availableActions,
	} = useTenderSelection(tenders);

	const { onTenderBatchAction, modal: BatchActionModal } =
		useTenderBatchActions(selectedTenders, clearSelectedTenders);

	const refreshFilters = (forceRefresh?: boolean, templ?: string) => {
		const filterTemplate =
			templ ??
			persistedFilters.filters?.tenders_filter_template ??
			"CUSTOM";
		if (filterTemplate !== "CUSTOM") {
			// load filter definition
			const template = templates.find(
				(t: any) => t.key === filterTemplate
			);
			if (!template) {
				setSelectedTemplate("CUSTOM");

				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_filter_template: "CUSTOM",
					})
				);
			} else {
				const transformedTemplate = parseFromTemplate(
					template.filter,
					identity
				);
				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_filter_template: filterTemplate,
						tenders_filters: transformedTemplate,
					})
				);
			}
		}

		setFilterRefreshDelay(delayUntilNextDay);
		if (forceRefresh) {
			setFiltersResetAt(new Date());
		}
	};

	useTimeout(() => refreshFilters(), filterRefreshDelay);

	useEffect(() => {
		refreshFilters();
		return () => {
			dispatch(initTenders());
		};
	}, []);

	useEffect(() => {
		if (activeView === TendersView.LIST_VIEW) {
			dispatch(initTenders());
			dispatch(getTenders(1, currentSearch));
		}
	}, [odataQuery, activeView]);

	const onHandleNextPage = useCallback(() => {
		if (!isPaginationStopped) {
			nextPage(currentPage + 1);
		}
	}, [isPaginationStopped, currentPage]);

	return (
		<>
			<TenderAndPricingFiltersDrawer
				page="Tenders"
				filtersResetAt={filtersResetAt}
				setSelectedtemplate={setSelectedTemplate}
			/>
			<PageTitle
				label="My Tenders"
				rightSide={
					<Box>
						<If condition={activeView === TendersView.LIST_VIEW}>
							<Box sx={{ display: "flex", gap: "16px" }}>
								<Formik
									initialValues={{ search: "" }}
									onSubmit={(values) => {
										dispatch(initTenders());
										onSearch(values?.search);
									}}
								>
									{({
										handleChange,
										handleSubmit,
										values,
									}) => {
										return (
											<form onSubmit={handleSubmit}>
												<CustomInput
													id="search"
													aria-label="search-input"
													value={values.search}
													onChange={handleChange}
													sx={style.searchInput}
													InputProps={{
														startAdornment: (
															<SearchIcon />
														),
													}}
													placeholder="Search"
												/>
												<PrimaryButton
													text="Search"
													type="submit"
													color="secondary"
													sx={{
														width: 106,
														top: "-3px",
													}}
												/>
												<Box
													sx={{
														display: "inline-flex",
													}}
												>
													<CustomSelect
														datatestId="pricing_template"
														name="template"
														label=""
														value={
															selectedTemplate ??
															"CUSTOM"
														}
														onChange={(event) => {
															setSelectedTemplate(
																event.target
																	.value as string
															);

															dispatch(
																setPersistedFilters(
																	{
																		...persistedFilters.filters,
																		tenders_filter_template:
																			event
																				.target
																				.value as string,
																	}
																)
															);

															refreshFilters(
																true,
																event.target
																	.value as string
															);
															setFiltersResetAt(
																new Date()
															);
														}}
														sx={{
															width: "250px",
															background: "#fff",
															borderRadius: "0px",
															marginLeft: "30px",
															marginRight: "20px",
														}}
														items={templates}
													></CustomSelect>
												</Box>

												<PrimaryButton
													text="Filters"
													type="button"
													color="secondary"
													sx={{
														width: 120,
														marginLeft: "10px",
														top: "-3px",
													}}
													onClick={() =>
														dispatch(
															setFiltersDrawerOpened(
																{
																	opened: true,
																}
															)
														)
													}
													startIcon={<TuneIcon />}
													endIcon={
														<Chip
															label={
																<div
																	style={{
																		fontSize:
																			"13px",
																	}}
																>
																	{filterCount.toString()}
																</div>
															}
															size="small"
														/>
													}
												/>
											</form>
										);
									}}
								</Formik>
								{groups.isOriginator && (
									<PrimaryButton
										testId="create-tender"
										text="Add Tender"
										role="link"
										href={PATH.NEW_TENDER}
										onClick={(e) => {
											e.preventDefault();
											navigate(PATH.NEW_TENDER);
											return false;
										}}
									>
										<AddCircleIcon sx={{ color: "#fff" }} />
									</PrimaryButton>
								)}
							</Box>
						</If>
						<Box
							sx={{
								display: "flex",
								justifyContent: "end",
								marginTop: "30px",
							}}
						>
							<ViewToggle
								activeView={activeView}
								setActiveView={setActiveView}
							></ViewToggle>
						</Box>
					</Box>
				}
			/>

			<PageContent
				variant={
					activeView === TendersView.LIST_VIEW
						? PageContentVariantType.fillScreen
						: PageContentVariantType.fillScreenWithScrollX
				}
			>
				<If condition={activeView === TendersView.LIST_VIEW}>
					<CollapsibleTable
						onTenderBatchAction={onTenderBatchAction}
						actions={availableActions}
						clearSelection={clearSelectedTenders}
						selectAll={selectAllTenders}
						toggleTenderSelection={toggleTenderSelection}
						selectedIds={selectedIds}
						tenders={tenders || []}
						isLoading={isTenderListLoading}
						onHandleNextPage={onHandleNextPage}
						currentPage={currentPage}
					/>
				</If>
				<If condition={activeView === TendersView.WORKFLOW_VIEW}>
					<TenderPageContext.Provider
						value={{ activeView, setActiveView }}
					>
						<WorkflowView />
					</TenderPageContext.Provider>
				</If>
			</PageContent>
			{BatchActionModal}
		</>
	);
}

export default TenderPage;
