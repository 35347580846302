import { useEffect } from "react";
import { useAppDispatch } from "../../../common/hooks/default";
import {
	initialGroupLoading,
	loadPricingsGroupCount,
} from "../../grouping/grouping.thunk";
import { selectPricingSelectedGroupingFields } from "../../grouping/grouping.selector";
import { useSelector } from "react-redux";

export function useInitialGroupLoading(tenderId?: number, odataQuery?: string) {
	const dispatch = useAppDispatch();
	const groups = useSelector(selectPricingSelectedGroupingFields);

	useEffect(() => {
		(async function () {
			if (tenderId && odataQuery !== "N/A") {
				if (!groups?.length) {
					await dispatch(initialGroupLoading(tenderId, odataQuery));
				} else {
					await dispatch(
						loadPricingsGroupCount(
							tenderId,
							odataQuery,
							false,
							groups
						)
					);
				}
			}
		})();
	}, [dispatch, tenderId, odataQuery, groups]);
}
