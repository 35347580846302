import { alpha } from "@mui/material";
import { TenderStatus } from "../pricing_list/pricingListSlice";
import { Tender } from "./tender.module";
import differenceInDays from "date-fns/differenceInDays";

export enum Order {
	asc = "asc",
	desc = "desc",
}

export const pricingTableHeaders = [
	"GR_ID",
	"P_ID",
	"Proxy",
	"Status",
	"Site/Portfolio",
	"Pricing Type",
	"Delivery Period",
	"instCap (MW)",
	"P50",
	"Customer P50(MWh)",
	"QTY (MWh)",
	"Cross",
	"Alpha baseload",
	"Beta contract",
	"Indic",
];

export const headerCells = [
	{
		id: "",
		text: "",
		filterBy: false,
	},
	{
		id: "status",
		text: "Status",
		filterBy: false,
	},
	{
		id: "name",
		text: "Name",
		filterBy: false,
	},
	{
		id: "client",
		text: "Client",
		filterBy: false,
	},
	{
		id: "country",
		text: "Country",
		filterBy: false,
	},
	{
		id: "instCapa",
		text: "InstCapa (MW)",
		filterBy: false,
	},
	{
		id: "sites",
		text: "#Sites",
		filterBy: false,
	},
	{
		id: "direction",
		text: "Direction",
		filterBy: false,
	},
	{
		id: "techno",
		text: "Techno",
		filterBy: false,
	},
	{
		id: "originator",
		text: "Originator",
		filterBy: false,
	},
	{
		id: "trader",
		text: "Trader",
		filterBy: false,
	},
	{
		id: "deadline",
		text: "Deadline",
		filterBy: false,
	},
	{
		id: "validity",
		text: "Validity",
		filterBy: false,
	},
	{
		id: "createdAt",
		text: "Created at",
		filterBy: false,
	},
	{
		id: "feedbacks",
		text: "Feedbacks",
		filterBy: false,
	},
	{
		id: "files",
		text: "Historical data",
		filterBy: false,
	},
	{
		id: "gemforce",
		text: "Gemforce",
		filterBy: false,
	},
	{
		id: "pricing_stage",
		text: "Pricing Stage",
		filterBy: false,
	},
	{
		id: "has_negative_price_clause",
		text: "Negative price",
		filterBy: false,
	},
	{
		id: "is_balancing_responsible_party",
		text: "BRP",
		filterBy: false,
	},
];

const convertColorToBackgroundAndShadow = (color: string) => {
	return {
		background: color == "#fff" ? "#fff" : alpha(color, 0.4),
		shadow: color,
	};
};

export const getTenderColorByStatusOrDeadline = (tender: Tender) => {
	switch (true) {
		case [TenderStatus.ONGOING, TenderStatus.PRICING_DONE].includes(
			tender.status
		):
			return convertColorToBackgroundAndShadow("#FDFD96");

		case [TenderStatus.UNDER_OFFER, TenderStatus.WON].includes(
			tender.status
		):
			return convertColorToBackgroundAndShadow("#D9544D");

		case [
			TenderStatus.BOOKED,
			TenderStatus.LOST,
			TenderStatus.CLOSED,
		].includes(tender.status):
			return convertColorToBackgroundAndShadow("#D3D3D3");

		case [TenderStatus.WON].includes(tender.status):
			return convertColorToBackgroundAndShadow("#98fb98");
		case differenceInDays(new Date(tender.due_date), new Date()) < 2 &&
			differenceInDays(new Date(tender.due_date), new Date()) >= 0:
			return convertColorToBackgroundAndShadow("#FF0000");
		default:
			return convertColorToBackgroundAndShadow("#fff");
	}
};

const workflowViewInfo = {
	[TenderStatus.NEW]: {
		title: "New",
	},
	[TenderStatus.TODO]: {
		title: "To do",
	},
	[TenderStatus.ONGOING]: {
		title: "Ongoing",
	},
	[TenderStatus.BOOKED]: {
		title: "Booked",
	},
	[TenderStatus.CLOSED]: {
		title: "Closed",
	},
	[TenderStatus.LOST]: {
		title: "Lost",
	},
	[TenderStatus.PRICING_DONE]: {
		title: "Pricing done",
	},
	[TenderStatus.TO_BE_CHECKED]: {
		title: "To be checked",
	},
	[TenderStatus.UNDER_OFFER]: {
		title: "Under Offer",
	},
	[TenderStatus.WON]: {
		title: "Won",
	},
};

export const getWorkflowViewInfo = (status?: TenderStatus) => {
	if (status && status in TenderStatus) {
		return workflowViewInfo[status];
	}
	return {
		title: "Not found",
	};
};

export enum TendersView {
	LIST_VIEW = "listView",
	WORKFLOW_VIEW = "workflowView",
}

export const ArchivedTenderStatus = [
	TenderStatus.LOST,
	TenderStatus.CLOSED,
	TenderStatus.BOOKED,
];
