import {
	NJFormItem,
	NJListItem,
	NJSelectInput,
} from "@engie-group/fluid-design-system-react";
import { kebabCase } from "../utils/kebabCase";
import { Box, Typography } from "@mui/material";
import { ErrorText } from "./ErrorText";
import { If } from "./If";

const style = {
	container: {
		width: 162,
	},
};

interface FluidSelectProps {
	items: { value: string; label?: string; key?: string | number }[];
	label: string;
	name: string;
	onChange: ((e: React.ChangeEvent<HTMLElement>) => any) &
		((newValue: string) => void);
	value?: string;
	hasError?: boolean;
	errorMessage?: string;
	isRequired?: boolean;
	isLabelStatic?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	description?: string;
	sx?: Record<string, any>;
}

const FluidSelect = ({
	items,
	label,
	name,
	onChange,
	value,
	errorMessage = "",
	hasError = false,
	isRequired = false,
	isReadOnly = false,
	isLabelStatic = false,
	isDisabled = false,
	description = "",
	sx = {},
}: FluidSelectProps) => {
	return (
		<Box sx={{ ...style.container, ...sx }}>
			<If condition={isReadOnly}>
				<NJFormItem
					id={name}
					labelKind={isLabelStatic ? "static" : "floating"}
					label={label}
					value={items.find((item) => item.value === value)?.label}
					readOnly
				/>
			</If>
			<If condition={!isReadOnly}>
				<NJSelectInput
					isDisabled={isDisabled}
					buttonDefaultValueLabel=""
					id={kebabCase(label)}
					labelKind={isLabelStatic ? "static" : "floating"}
					label={label}
					listNavigationLabel=""
					name={name}
					onChange={onChange}
					value={value}
					hasError={hasError}
					isRequired={isRequired}
				>
					{items.map((item) => {
						return (
							<NJListItem
								key={item?.key || item.value}
								value={item.value}
							>
								{item.label}
							</NJListItem>
						);
					})}
				</NJSelectInput>
			</If>
			<Typography variant="subtitle2">{description}</Typography>
			<ErrorText>{errorMessage}</ErrorText>
		</Box>
	);
};

export default FluidSelect;
