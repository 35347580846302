import { GenericModel } from "../../common/components/GenericTable";

interface Technology {
	id: number;
	name: string;
}

interface Coordinates {
	DECIMAL: {
		latitude: string;
		longitude: string;
	};
	DEG_MIN_SEC: {
		value: string;
	};
	GAUSS_KRUGER: {
		latitude: string;
		longitude: string;
	};
	LAMBERT: {
		latitude: string;
		longitude: string;
	};
}

export enum SiteProxyGenerationStatus {
	IN_PROGRESS = "IN_PROGRESS",
	GENERATED = "GENERATED",
	FAILURE = "FAILURE",
}

export enum SiteKnownStatus {
	NEW = "NEW",
	KNOWN_GECO = "KNOWN_GECO",
	KNOWN_GEPO = "KNOWN_GEPO",
}

export interface Site extends GenericModel {
	ts_in_use: string;
	id: number;
	asset_id?: string;
	name: string;
	specific_name: string;
	country: string;
	cluster_names?: string;
	proxy_generation_date?: string;
	proxy_generation_status?: SiteProxyGenerationStatus;
	installed_capacity_mw?: number;
	latitude: number;
	longitude: number;
	techno?: Technology;
	coordinates: Coordinates;
	park_age?: number;
	proxy_generation_errors?: string;
	is_pricing_ready?: boolean;
	is_master_site?: boolean;
	count_attached_pricings?: number;
	ts?: any[];
	known_status?: SiteKnownStatus;
	known_status_loading?: boolean;
	known_status_error?: any;
	real_power_production: number;
	cluster_p50: number;
	could_refresh_cluster_status?: boolean;
	hub_height?: number;
	tilt_angle?: number;
	azimuth?: number;
	max_grid_injection_mw?: number;
	is_curtailable?: boolean;
	curtailability_subsidy_price?: number;
}

export interface Portfolio extends GenericModel {
	id: number;
	name: string;
	isNew?: boolean;
	sites: Site[];
	children: Site[];
	park_age?: number;
	count_attached_pricings?: number;
}

export function isPortfolio(data: Site | Portfolio): data is Portfolio {
	return !!data.children;
}

export function isSite(data: Site | Portfolio): data is Site {
	return !isPortfolio(data);
}

export function getSiteOrPortfolioCountry(data?: Site | Portfolio) {
	if (!data) {
		return "BE";
	}
	if (isPortfolio(data)) {
		return data.children?.[0]?.country || "BE";
	} else {
		return data?.country || "BE";
	}
}
