import {
	TableCell,
	Box,
	Typography,
	Chip,
	LinearProgress,
	Tooltip,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Checkbox } from "rsuite";
import { FlashUpdate } from "../../../common/components/FlashUpdate";
import { GroupedField, groupId } from "../../grouping/grouping.module";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { If } from "../../../common/components/If";
import { Column } from "../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../pricing_list/hooks/columns/common";
import { usePricingTableRowModel } from "../../pricing_list/hooks/columns/usePricingTableRowModel";
import { pricingsAreSelected, subGroupsAreSelected } from "../groupUtils";

export interface GroupLineProps {
	groupedField: GroupedField;
	depth: number;
	parents: GroupedField[];
	onGroupSelection: (groupPath: string[]) => void;
	onGroupExpand: (groupPath: string[]) => void;
	shouldSpin?: boolean;
	selectedColumns?: Column<ColumnIds>[];
}

const styles = {
	icon: {
		fontSize: "14px",
		color: "#007ACD",
		transform: "rotate(90deg)",
	},
	iconWrapper: {
		borderRadius: "100%",
		"&:hover": {
			background: "rgba(0, 0, 0,.1)",
		},
		padding: "8px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	iconExpanded: {
		transform: "rotate(270deg)",
	},
	valueCellWrapper: {
		position: "sticky",
		zIndex: 1,
		left: 0,
		top: 74,
		padding: 0,
		height: "34px",
		border: 0,
		display: "flex",
		flexDirection: "column",
	},
	cell: {
		border: 0,
		position: "sticky",
		top: 74,
	},
	cellWrapper: {
		gap: "10px",
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		whiteSpace: "nowrap",
		maxHeight: "30px",
	},
	clickable: {
		cursor: "pointer",
	},
	groupName: {
		fontSize: "16px",
		userSelect: "none",
		color: "#435C6E",
		fontWeight: "700",
	},
	count: {
		color: "#005AA0",
		background: "#0F55E11A",
		fontSize: "16px",
		fontWeight: "700",
		height: "28px",
		minWidth: "28px",
		".MuiChip-label": {
			margin: 0,
			padding: 0,
		},
	},
};

function GroupLineComponent(props: GroupLineProps) {
	const {
		groupedField,
		depth,
		onGroupSelection,
		onGroupExpand,
		parents,
		shouldSpin,
		selectedColumns,
	} = props;

	const rowModel = usePricingTableRowModel(selectedColumns);

	const isExpanded = useMemo(() => !!groupedField.isExpanded, [groupedField]);
	const isSelected = useMemo(() => !!groupedField.isSelected, [groupedField]);
	const isSelectionDisabled = useMemo(
		() => parents.reduce((acc, curr) => acc || !!curr.isSelected, false),
		[parents]
	);

	const arrowStyle = useMemo(() => {
		let style = { ...styles.icon };
		if (isExpanded) {
			style = { ...style, ...styles.iconExpanded };
		}
		return style;
	}, [isExpanded]);

	const onSelection = useCallback(() => {
		onGroupSelection(parents.map(groupId).concat(groupId(groupedField)));
	}, [parents, onGroupSelection]);
	const onExpand = useCallback(() => {
		onGroupExpand(parents.map(groupId).concat(groupId(groupedField)));
	}, [parents, onGroupSelection]);

	const checked = useMemo(
		() => isSelected || isSelectionDisabled,
		[isSelected, isSelectionDisabled]
	);
	const indeterminate = useMemo(() => {
		if (checked) {
			return false;
		}
		return (
			pricingsAreSelected(groupedField) ||
			subGroupsAreSelected(groupedField)
		);
	}, [groupedField, checked]);

	return (
		<>
			<TableCell sx={styles.valueCellWrapper}>
				<FlashUpdate
					field={props.groupedField.count}
					isUpdate={props.groupedField.isNewUpdate}
					sx={{
						...styles.cellWrapper,
						marginLeft: `${depth * 20}px`,
					}}
				>
					<Checkbox
						checked={checked}
						disabled={isSelectionDisabled}
						onChange={onSelection}
						indeterminate={indeterminate}
					/>
					<Box
						sx={styles.iconWrapper}
						data-testid={`group-${props.groupedField.field_key}-${props.groupedField.field_value}`}
						data-toggle={isExpanded ? "open" : "false"}
						onClick={onExpand}
					>
						<ArrowForwardIosIcon sx={arrowStyle} />
					</Box>
					<Tooltip title={props.groupedField.group_label}>
						<Typography sx={styles.groupName}>
							{props.groupedField.field_value}
						</Typography>
					</Tooltip>
					<Chip
						label={"" + props.groupedField.count}
						sx={styles.count}
					/>
				</FlashUpdate>
				<If condition={shouldSpin}>
					<LinearProgress />
				</If>
			</TableCell>
			{rowModel.nonStickyColumns.map((column) => (
				<TableCell sx={styles.cell} key={column.columnId} />
			))}
			<TableCell sx={styles.cell} />
			<TableCell sx={styles.cell} />
		</>
	);
}

export const GroupLine = React.memo(GroupLineComponent);
