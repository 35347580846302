import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GroupingField } from "../../../features/metadata/metadata.module";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { secondaryColor } from "../../../core/theme";
import { If } from "../If";
import * as _ from "lodash";
import { GroupPill } from "./GroupPill";

const styles = {
	wrapper: {
		display: "flex",
		marginTop: "20px",
		border: `2px solid ${secondaryColor}`,
		marginRight: "20px",
	},
	titlePill: {
		backgroundColor: secondaryColor,
		display: "flex",
		alignItems: "center",
		gap: "4px",
		padding: "0 16px",
		height: "100%",
	},
	groupListWrapper: {
		display: "flex",
		alignItems: "center",
		padding: "8px",
		minHeight: "52px",
		gap: "4px",
	},
};

export interface GroupsSelectorProps {
	availableGroups: GroupingField[];
	selectedGroups: GroupingField[];
	onAddgroup: (group: GroupingField) => void;
	onRemoveGroup: (group: GroupingField) => void;
	setGroups: (newGroups: GroupingField[]) => void;
	onReset: () => void;
	isLoading?: boolean;
}

export function GroupsSelector(props: GroupsSelectorProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleCloseAddPanel = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);
	const handleOpenAddPanel = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		},
		[setAnchorEl]
	);
	const handleClickOnAddGroup = useCallback(
		(event: React.MouseEvent<any>, group: GroupingField) => {
			handleCloseAddPanel();
			props.onAddgroup(group);
		},
		[handleCloseAddPanel, props.onAddgroup]
	);

	const unselectedGroups = useMemo(
		() => _.xorBy(props.selectedGroups, props.availableGroups, "field_key"),
		[props.selectedGroups, props.availableGroups]
	);

	// Used for grad and drop (make pills move, but don't trigger reload before drop)
	const [tempSelectedGroups, setTempSelectedGroups] = useState<
		GroupingField[]
	>([]);
	const swapTemp = useCallback(
		(from: number, to: number) => {
			setTempSelectedGroups((prev) => {
				const copy = [...prev];
				copy.splice(from, 1);
				copy.splice(to, 0, prev[from]);
				return copy;
			});
		},
		[setTempSelectedGroups]
	);
	useEffect(() => {
		setTempSelectedGroups(props.selectedGroups);
	}, [props.selectedGroups]);
	const onDrop = useCallback(() => {
		props.setGroups(tempSelectedGroups);
	}, [props.setGroups, tempSelectedGroups]);

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.titlePill}>
				<If condition={props.selectedGroups.length === 0}>
					<FilterListIcon />
				</If>
				<If condition={props.selectedGroups.length}>
					<IconButton
						onClick={props.onReset}
						disabled={props.isLoading}
					>
						<ClearIcon />
					</IconButton>
				</If>
				<span>Groups</span>
			</Box>
			<Box sx={styles.groupListWrapper}>
				{tempSelectedGroups.map((group, index) => (
					<React.Fragment key={group.field_key}>
						<GroupPill
							onDrop={onDrop}
							group={group}
							index={index}
							onDelete={props.onRemoveGroup}
							swapGroups={swapTemp}
						/>
						<If condition={index < tempSelectedGroups.length - 1}>
							<ChevronRightIcon />
						</If>
					</React.Fragment>
				))}
				<If condition={unselectedGroups.length > 0}>
					<IconButton
						id="add-groups-button"
						aria-controls={open ? "add-groups" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleOpenAddPanel}
						disabled={props.isLoading}
					>
						<AddIcon />
					</IconButton>
					<Menu
						id="add-groups"
						anchorEl={anchorEl}
						open={open}
						onClose={handleCloseAddPanel}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{unselectedGroups.map((group) => (
							<MenuItem
								data-testid={"group-" + group.field_key}
								key={group.field_key}
								onClick={(ev) =>
									handleClickOnAddGroup(ev, group)
								}
							>
								{group.display_name}
							</MenuItem>
						))}
					</Menu>
				</If>
			</Box>
		</Box>
	);
}
